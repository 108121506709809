<template>
    <Layout>
        <div class="back-btn" v-if="is_rewards" @click='displayComponent'><i class="mdi mdi-keyboard-backspace mr-2"></i> Back</div>
        <PageHeader v-if="!is_rewards" :title="title1" :items="items1" />
        <PageHeader v-if="is_rewards"  :title="title2" :items="items2" />
        <div class="row">
            <div class="col-lg-12">
                <PrimeUsers v-if="!is_rewards" @viewRewards="viewRewards"></PrimeUsers>
                <ViewRewards v-if="is_rewards" :rewards="rewards"></ViewRewards>
            </div>
        </div>
    </Layout>
</template>
  <style>
    .back-btn {
        padding: 5px 10px;
        font-size: 20px;
        background: #252b3b;
        justify-content: center;
        display: flex;
        align-items: center;
        width: 100px;
        color: #fff;
        border-radius: 5px;
        margin-bottom: 10px;
        cursor: pointer;
    }
  </style>
<script>
import appConfig from "@/app.config";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import PrimeUsers from "./primeUsers.vue";
import ViewRewards from "./viewRewards.vue";
  export default {
    components: {
    Layout,
    PageHeader,
    PrimeUsers,
    ViewRewards
},
    data() {
      return {
        title1: "Prime User Report",
        items1: [
          {
            text: "Medisage",
          },
          {
            text: "Report",
            active: true,
          },
        ],
        title2: "Prime User Rewards",
        items2: [
          {
            text: "Medisage",
          },
          {
            text: "Rewards",
            active: true,
          },
        ],
        loading: true,
        rewards: [],
        is_rewards: false,
      };
    },
    created() {
      // this.init();
    },
    methods: {
        async getUserData(data) {
            try {
                this.rewards = [];
                const response = await this.$http.get(
                    appConfig.api_base_url + "/users/prime-member-report/" + data
                );
                if(response.data.data.success) {
                    this.rewards = response.data.data.items;
                }
                
            } catch (err) {
                console.log(err);
            }
        },
        viewRewards(member_ref_no) {
            this.is_rewards = true;
            this.getUserData(member_ref_no);
        },
        displayComponent() {
            this.is_rewards = false;
        }
    },
    watch: {
      filters: {
        deep: true,
        handler() {
          // this.init();
        },
      },
    },
  };
  </script>
  