<template>
    <div>
        
            <template v-if="rewards != '' ">
                <div class="rewards-grid">
                    <b-card v-for="(data, index) in rewards " :key="index"
                        img-src="https://picsum.photos/600/300/?image=25"
                        img-alt="Image"
                        img-top
                        tag="article"
                        style="max-width: 20rem;"
                        class="mb-2"
                    >
                        <div class="rewards-text-points">
                            <div class="rewards-reason">{{data.credit_reason}}</div>
                            <div class="rewards-value">+{{data.value}}</div>
                        </div>
                    </b-card>
                </div>
            </template>
            <template v-else>
                <div class="not-found">Not Found</div>
            </template>  
    </div>
    
</template>
<style>
.not-found {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
}
.rewards-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 20px;
}
.rewards-text-points {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-gap: 10px;
}
.rewards-reason {
    text-align: center;
    justify-self: center;
    align-self: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 1;
}
.rewards-value {
    background: #ece8e9;
    padding: 5px 20px;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 600;
    justify-self: center;
    align-self: center;
}
.card-body {
    padding: 10px;
}
</style>
<script>
export default {
    props: ["rewards"],
    components: {
    },
    data() {
    return {
        title: "View Rewards",
        items: [
          {
            text: "Medisage",
          },
          {
            text: "Report",
            active: true,
          },
        ],
        loading: true,
    }
    },
    watch: {
    filters: {
        deep: true,
        handler(data) {
            this.getUserData(data); 
        },
    },
    },
    computed: {
    },
    methods: {
        
    },
    mounted() {
    },
};
</script>
